import React from 'react'
import './Home.scss'
import N1 from '../../img/Home1.jpeg'
import N2 from '../../img/Home2.jpeg'
import N3 from '../../img/Home3.jpeg'
import N4 from '../../img/Home4.jpeg'



export default function index() {
    return (
        <div className='' >
            <div id="carouselExampleAutoplaying" class="carousel slide" data-bs-ride="carousel">
                <div class="carousel-indicators">
                    <button type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide-to="0" class="active" aria-current="true" aria-label="Slide 1"></button>
                    <button type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide-to="1" aria-label="Slide 2"></button>
                    <button type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide-to="2" aria-label="Slide 3"></button>
                    <button type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide-to="3" aria-label="Slide 4"></button>
                </div>
                <div class="carousel-inner">
                    <div class="carousel-item active">
                        <img src={N1} class="d-block w-100 " alt="..." />
                        <div class="carousel-caption d-none d-md-block">
                            {/* <h4 className='slideTitle'>The Beginning of Project</h4> */}
                        </div>
                    </div>
                    <div class="carousel-item ">
                        <img src={N2} class="d-block w-100 " alt="..." />
                        <div class="carousel-caption d-none d-md-block">
                            {/* <h4 className='slideTitle'>The Beginning of Project</h4> */}
                        </div>
                    </div>
                    <div class="carousel-item">
                        <img src={N3} class="d-block w-100 " alt="..." />
                        <div class="carousel-caption d-none d-md-block">
                            {/* <h4 className='slideTitle'>During the Construction</h4> */}
                        </div>
                    </div>
                    <div class="carousel-item">
                        <img src={N4} class="d-block w-100 " alt="..." />
                        <div class="carousel-caption d-none d-md-block">
                            {/* <h4 className='slideTitle'>During the Construction</h4> */}
                        </div>
                    </div>
                </div>
                <button class="carousel-control-prev" type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide="prev">
                    <span class="carousel-control-prev-icon" aria-hidden="true"></span>
                    <span class="visually-hidden">Previous</span>
                </button>
                <button class="carousel-control-next" type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide="next">
                    <span class="carousel-control-next-icon" aria-hidden="true"></span>
                    <span class="visually-hidden">Next</span>
                </button>
            </div>
        </div>
    )
}
