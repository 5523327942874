import React from 'react'
// import BlackWhiteLogo from '../../imgs/AtaiahBlackWhiteCopy.png'

export default function index() {
    return (
        <div className='container' id="contact-us">
            <div className='row'>
                <div className='col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-sm-12 mt-5'>
                    <h3 className='subtitle'>
                        International Medical Health Canada
                    </h3>
                    {/* <img src={BlackWhiteLogo} alt="Ataiah logo in Black color" width="200rem" height="100rem" /> */}
                    <p className='mt-2' style={{ color: "#727274" }}>A registered Canadian business.</p>
                    <p style={{ color: "#727274" }}>Corporation No: BC1278332</p>
                </div>
                <div className='col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-sm-12 mt-5'>
                    <h3 className='subtitle'>
                        Contact US
                    </h3>
                    <p><i class="fas fa-phone icons"></i> <a className="contact-us-text" href="tel:+16476192116"> +1 (647) 619-2116 CAN</a></p>
                    <p><i class="fas fa-envelope icons"></i> <a className="contact-us-text" target="_blank" rel="noreferrer" href="mailto:info@ataiah.com">info@imhcan.com</a></p>
                    <p><i class="fas fa-map-marker icons"></i> <a className="contact-us-text" target="_blank" rel="noreferrer" href='https://maps.google.com/?q=77 Hornby Street, Vancouver, BC V6Z 1S4'>777 Hornby Street, Unit 600, Vancouver, BC V6Z 1S4</a></p>
                </div>
            </div>
        </div>
    )
}
