import React, { useState } from 'react'
// import { Link } from 'react-router-dom'
import '../../styles.scss';


export default function Navbar({ moveToHome, moveToAbout, moveToServices, moveToContact }) {

    const [navbarBlur, setNavbarBlur] = useState(false);

    // check the scroll pixel of navbar to apply the blur effect
    const changeNavbarBackground = () => {
        if (window.scrollY > 52) {
            setNavbarBlur(true)
        }
        else {
            setNavbarBlur(false)
        }
    }

    // event lister for scrolling
    window.addEventListener('scroll', changeNavbarBackground)
    return (
        <>
            <nav className={navbarBlur ? "navbar navbar-expand-lg navbar-light navbar-section blurred" : "navbar navbar-expand-lg navbar-light navbar-section"}>
                <div className="container-fluid">
                    {/* <Link to="/"><img id="logo" alt="FN logo" src={"Logo"} style={{ top: "2px", left: "2px", width: "40px", height: "40px" }} /></Link> */}
                    <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNav" aria-controls="navbarToggleExternalContent" aria-expanded="false" aria-label="Toggle navigation">
                        <span className="navbar-toggler-icon"></span>
                    </button>
                    <div className="collapse navbar-collapse justify-content-center" id="navbarNav">
                        <ul className="navbar-nav" >
                            <li className="nav-item">
                                <a id="btnHome" role="button" className="nav-link active " aria-current="page" href="#home" onClick={moveToHome}>Home</a>
                            </li>
                            <li className="nav-item">
                                <a id="btnAbout" role="button" className="nav-link active " aria-current="page" href="#about" onClick={moveToAbout}>About</a>
                            </li>
                            {/* <li className="nav-item">
                                <a id="btnServices" role="button" className="nav-link active " aria-current="page" href="#services" onClick={moveToServices}>Services</a>
                            </li> */}
                            <li className="nav-item">
                                <a id="btnContact" role="button" className="nav-link active " aria-current="page" href="#contact" onClick={moveToContact}>Contact</a>
                            </li>
                        </ul>
                    </div>
                </div>
            </nav>
        </>
    )
}
