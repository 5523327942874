import React from 'react'
import CardMsg from '../CardMsg'
import './About.scss'

export default function index() {
    return (
        <div className='container'>
            <div className='row'>
                <div className='col-xxl-6 col-xl-6 col-lg-6 col-md-12 col-sm-12'>
                    <CardMsg cardTitle="About" cardMsg="International Medical Health Est. is a pioneering supplier in the field of Medical products and Medical Gas systems. We are philanthropic in our approach placing human services above business model. We provide superior quality, reasonable pricing, technically institutionalized advanced medical products, ensuring a qualitative after sales back up, and most important building the road of trust with our customers. Our products are supplied all over Middle East and we are the Exclusive Distributors of Amico Corporation" />
                </div>
                <div className='col-xxl-6 col-xl-6 col-lg-6 col-md-12 col-sm-12'>
                    <CardMsg cardTitle="Vision" cardMsg="Our aspiration is to be at the forefront of Medical products and Gas systems, establishing new standards for quality, innovation, and ethical excellence. Rooted in philanthropy, we prioritize human welfare over mere business interests. Our unwavering commitment focuses on delivering paramount value through high-quality medical products, offered at reasonable prices. Moreover, we go beyond transactions, nurturing enduring trust-based relationships with our esteemed clientele through comprehensive after-sales support" />
                </div>

            </div>
        </div>
    )
}
