import React from 'react'

export default function index({ cardTitle, cardMsg }) {
    return (
        <div className='card card-innner-padding rounded-corner mt-4'>
            <h3 className='message-card-title' style={{ color: "#069FCD" }}>{cardTitle}</h3>
            <p className='message-card-text' style={{ color: "#727274" }} >{cardMsg}</p>
        </div>
    )
}
