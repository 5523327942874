import React from 'react'
// import '../../styles.scss'
import './Footer.scss'

export default function Footer() {

    return (
        <footer>
            <div id="footer" className='container text-center'>
                <div id="footer-copyright" className='mt-3'>&#169;2023 IMHCA Inc. All rights reserved</div>
            </div>
        </footer>
    )
}
